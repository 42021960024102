import { createRouter, createWebHistory } from 'vue-router'
import generalRouter from './WebRoutes/GeneralSite'

const routes = [
  {
    path: '/',
    name: 'general', 
    component: () => import('../components/WebUtils/MainContainer.vue'),
    children: generalRouter
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

export default router
