<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
    head(){
        return { 
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Woningkenmerkenrapport',
            // all titles will be injected into this template
            titleTemplate: '%s | Woningkenmerkenrapport'
          }
        }
  }
</script>