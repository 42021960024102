import { createApp } from 'vue'
import { createHead, VueHeadMixin } from "@unhead/vue"
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Axios from 'axios'
import VueGtag from "vue-gtag";

Axios.defaults.baseURL = 'https://wkr-serv.cld9.nl/api/';
Axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";


import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'

library.add(fat, fal, fad, far)

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import './styles/app.css'; 

createApp(App)
.use(store)
.mixin(VueHeadMixin)
.use(createHead())
.use(router)
.use(VueGtag, {
    config: { id: "G-CD1XJB75S3" }
  }, router)
.mount('#app')