const routes = [
    {
        path: '/',
        name: 'homePage',
        component: () => import('../../views/WebPages/HomePage.vue')
    },
    {
        path: '/contact',
        name: 'contactPage',
        component: () => import('../../views/WebPages/ContactPage.vue')
    },
    {
        path: '/inloggen',
        name: 'loginPage',
        component: () => import('../../views/auth/LoginPage.vue')
    }, 
    {
        path: '/voorbeeld-rapport',
        name: 'exampleReportPage',
        component: () => import('../../views/WebPages/ExampleReportPage.vue')

    }
]

export default routes