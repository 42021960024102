import { createStore } from 'vuex'
import Axios from 'axios'

export default createStore({
  state: {
    user: {
      name: '',
      email: '',
    }
  },
  getters: {
  },
  mutations: {
    login(state, user) {
      state.user = user
    }
  },
  actions: {
   async login({ commit }, user) {
    return await Axios.post('login', user)
   .then((response) => {
      commit('login', response.data)
      return response.data
    })
    .catch(() => {
      return false;
    }
    )}
  },
  modules: {
  }
})
